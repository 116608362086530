<template>
  <div v-show="!state.isLoading">
    <h2 class="mt-sm-0 my-3">{{ t("title.registrationPage") }}</h2>
    <div class="alert alert-dark alert-dismissible fade show" role="alert">
      {{ t('info.registrationLocalStorage') }}
      <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    </div>
    <div class="d-md-flex gap-2">
      <form id="form" class="flex-fill" action="#" @submit.prevent="getIdVerificationInfoAndPhotoByAppId">
        <div class="input-group">
          <div class="input-group-text">
            <i v-if="!state.isPerformingLookup" class="bi bi-credit-card-2-front text-dark"></i>
            <i v-if="state.isPerformingLookup" class="spinner-border spinner-border-sm text-secondary"></i>
          </div>
          <input type="text"
                  id="input"
                  v-model="appIdAndSnInput"
                  class="form-control"
                  :placeholder="t('info.registrationPlaceholder')"
                  :disabled="state.isPerformingLookup"
                  autofocus
          />
          <button class="btn btn-outline-secondary" type="button" @click="clearAndRefocusInput"><i class="bi bi-x-lg"></i></button>
        </div>
      </form>
      <div class="d-grid">
            <button @click="resetLocalStorage" class="btn btn-danger text-nowrap w-100" id="clearBtn">
              <i class="bi bi-trash"></i>
              {{ t('button.clearList') }}
            </button>
      </div>
      <div class="d-grid">
            <button type="button" class="btn btn-primary text-nowrap w-100" id="importBtn" data-bs-toggle="modal" data-bs-target="#listImport"
                    @click="focusModalTextArea" :disabled="state.keepPolling">
              <i class="bi bi-box-arrow-in-up"></i>
              {{ t('button.inputAppId') }}
            </button>
      </div>
    </div>

    <div v-if="state.errorList.length !== 0" class="alert alert-danger alert-dismissible my-3" role="alert">
      <button type="button" class="btn-close" @click="closeError" aria-label="Close"></button>
      <div v-for="(error, i) in state.errorList" :key="UtilService.generateId(error.applicationId)">
        <div v-if="i === 0">
          {{ t(error.errorMessage) }}{{ error.applicationId ? ' ID: ' : '' }}
        </div>
        <span class="font-monospace">{{ error.applicationId }}</span>
      </div>
    </div>
    <NotificationSuccess v-if="state.successMsg" :message="state.successMsg"
                          @messageShown="messageShown"></NotificationSuccess>
    <div v-if="state.importProgress.total !== 0" class="my-3">
      <h3>{{ t('info.importing') }}</h3>
      <b-progress :max="state.importProgress.total" height="2rem" animated>
        <b-progress-bar :value="state.importProgress.current">
          <span><strong>{{ state.importProgress.current }} / {{ state.importProgress.total }}</strong></span>
        </b-progress-bar>
      </b-progress>
    </div>


    <div class="modal fade" id="listImport" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ t('modal.prompt.question') }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    @click="refocusInputWindowWithDelay(500)">
            </button>
          </div>
          <div class="modal-body">
          <textarea type="text"
                    id="modalInput"
                    rows="10"
                    cols="30"
                    v-model="state.textArea"
                    class="form-control font-monospace"
                    :placeholder="t('info.registrationPlaceholderImport')"
          />
          </div>
          <div class="modal-footer">
            <p class="me-auto" :class="exceedImportLimit ? 'invalid' : ''">
              {{ lineCountImport }}/{{ IMPORT_LIMIT }}
              <span v-if="exceedImportLimit">{{ t('error.limitNumberOfCalls') }}</span>
            </p>
            <button type="submit" class="btn btn-primary" id="submitImport" @click="processAppIdList" data-bs-dismiss="modal" :disabled="exceedImportLimit">
              {{ t('modal.confirm') }}
            </button>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                    @click="refocusInputWindowWithDelay(500)">
              {{ t('modal.cancel') }}
            </button>
          </div>
        </div>
      </div>
    </div>



    <IdVerificationTable v-if="state.storageList.length !== 0"
                         class="mt-4 overflow-auto"
                         :key="state.storageList"
                         :users="state.storageList"
                         @personRemoved="successfulRemove"
    ></IdVerificationTable>

    <b-container :toast="{root: true, zIndex: 1000}" fluid="sm" position="position-fixed" class="z-toast-container"></b-container>
  </div>

</template>

<script>

import {reactive, ref, computed, h} from 'vue';
import {useI18n} from "vue-i18n";
import AccountService from "@/service/service";
import 'jquery/dist/jquery.min.js';
// import 'bootstrap'
// import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import IdVerificationTable from "@/components/IdVerificationTable";
import NotificationSuccess from "@/components/NotificationSuccess";
import {mapActions, mapGetters} from "vuex";
import {useMeta} from "vue-meta";
import MappingService from "@/service/mapping-service";
import {useToast} from 'bootstrap-vue-3';
import UtilService from '@/service/util-service';

export default {
  name: "Registration",
  components: {
    NotificationSuccess,
    IdVerificationTable
  },
  setup() {
    useMeta({
      title: 'Associatie KU Leuven - kaart'
    })
    const {t} = useI18n();
    const toast = useToast();
    const accountService = new AccountService();

    const state = reactive({
      isLoading: true,
      isPerformingLookup: false,
      successMsg: "",
      textArea: "",
      errorList: [],
      storageList: getLocalStorageAsJson(),
      importProgress: {
        total: 0,
        current: 0
      },
      keepPolling: false
    });

    const appIdAndSnInput = ref("");
    const IMPORT_LIMIT = Number(process.env.VUE_APP_IMPORT_LIMIT);

    function pollImportProgress() {
      const importProgress = JSON.parse(sessionStorage.getItem("importProgress"));
      if (importProgress) {
        state.importProgress = importProgress;
      }
      if (state.keepPolling) {
        setTimeout(pollImportProgress, 500);
      } else {
        const emptyProgress = { total: 0, current: 0 };
        sessionStorage.setItem("importProgress", JSON.stringify(emptyProgress));
        state.importProgress = emptyProgress;
      }
    }

    function refocusInputWindowWithDelay(delay) {
      setTimeout(function () {
        document.getElementById("input").focus();
      }, delay)
    }

    function focusModalTextArea() {
      setTimeout(function () {
        document.getElementById("modalInput").focus();
      }, 500)
    }

    function makeToast(title, content, variant) {
      toast.show(
        {
          title: title,
          body: content
        },
        {
          pos: 'bottom-right',
          delay: 15000,
          variant: variant
        }
      );
    }

    async function getIdVerificationInfoByAppId() {
      const appIdAndSn = UtilService.normalizeAppIdAndSn(appIdAndSnInput.value);
      appIdAndSnInput.value = "";
      if (appIdAndSn) {
        state.successMsg = "";
        state.isPerformingLookup = true;
        await accountService.getIdVerificationByAppId(appIdAndSn)
          .then(userInfoData => {
            state.storageList = getLocalStorageAsJson();
            makeToast(t('notification.personAddedSuccessfully'), `${userInfoData.firstName} ${userInfoData.lastName} (${userInfoData.userName})`, 'success');
            state.isPerformingLookup = false;
            refocusInputWindowWithDelay(0);
          })
          .catch(error => {
            makeToast(t('error.error'), t(error.message), 'danger');
            UtilService.pushToErrorList(state.errorList, appIdAndSn, error.message);
            state.isPerformingLookup = false;
            refocusInputWindowWithDelay(0);
          });
      }
    }

    async function getIdVerificationInfoAndPhotoByAppId() {
      const appIdAndSn = UtilService.normalizeAppIdAndSn(appIdAndSnInput.value);
      appIdAndSnInput.value = "";
      if (appIdAndSn) {
        state.successMsg = "";
        state.isPerformingLookup = true;
        try {
          const infoData = await accountService.getIdVerificationByAppId(appIdAndSn);
          const infoMessage = `${infoData.firstName} ${infoData.lastName} (${infoData.userName})`;
          state.storageList = getLocalStorageAsJson();

          try {
            const photoData = await accountService.getIdVerificationPhotoByAppId(appIdAndSn);
            const src = photoData.photo ? `data:image/jpeg;base64,${photoData.photo}` : require('@/assets/images/default-picture.jpg');
            const vnode = h('div', { class: 'text-center' }, [
              h('img', {
                'src': src,
                'style': {
                  'maxWidth': '250px',
                  'maxHeight': '250px',
                  'width': 'auto',
                  'height': 'auto',
                  'border-radius': '10px'
                }
              }),
            ]);

            makeToast(infoMessage, vnode, 'success');
          } catch (error) {
            makeToast(t('notification.personAddedSuccessfully'), infoMessage, 'success');
          }

        } catch (error) {
          makeToast(t('error.error'), t(error.message), 'danger');
          UtilService.pushToErrorList(state.errorList, appIdAndSn, error.message);
        } finally {
          state.isPerformingLookup = false;
          refocusInputWindowWithDelay(0);
        }
      }
    }

    async function processAppIdList() {
      state.keepPolling = true;
      pollImportProgress();
      await accountService.processAppIdList(state.textArea)
        .then(errorList => {
          state.storageList = getLocalStorageAsJson();
          state.textArea = "";
          errorList.forEach(error => UtilService.pushToErrorList(state.errorList, error.applicationId, error.errorMessage));
          state.keepPolling = false;
        })
      refocusInputWindowWithDelay(0);
    }

    function resetLocalStorage() {
      if (confirm(t('modal.confirmEraseAll'))) {
        localStorage.clear();
        state.storageList = [];
        state.successMsg = "";
        closeError();
        state.textArea = "";
      }
    }

    function closeError() {
      state.errorList = [];
    }

    function getLocalStorageAsJson() {
      const userInfoList = localStorage.getItem("userInfoList");
      return userInfoList ? MappingService.mapIdVerificationJsonListToObject(JSON.parse(userInfoList)) : [];
    }

    function successfulRemove() {
      state.successMsg = "notification.personRemovedSuccessfully"
    }

    function messageShown() {
      state.successMsg = "";
    }

    function clearAndRefocusInput() {
      appIdAndSnInput.value = "";
      refocusInputWindowWithDelay(0);
    }

    const lineCountImport = computed(() =>
      UtilService.cleanAndTrimListOfAppIdAndSns(state.textArea).length
    )

    const exceedImportLimit = computed(() =>
      lineCountImport.value > IMPORT_LIMIT
    )

    return {
      t,
      getIdVerificationInfoByAppId,
      getIdVerificationInfoAndPhotoByAppId,
      resetLocalStorage,
      successfulRemove,
      messageShown,
      processAppIdList,
      closeError,
      refocusInputWindowWithDelay,
      focusModalTextArea,
      appIdAndSnInput,
      state,
      clearAndRefocusInput,
      lineCountImport,
      exceedImportLimit,
      IMPORT_LIMIT,
      UtilService
    }
  },
  computed: {
    ...mapGetters(
      {userInfoFromStore: "getUserInfo"}
    )
  },
  async created() {
    await this.fetchUserInfo()
      .then(() => {
        if (!this.userInfoFromStore.name) {
          window.location.replace(window.location.href);
        } else {
          this.state.isLoading = false;
        }
      })
  },
  methods: {
    ...mapActions(['fetchUserInfo'])
  }
}
</script>

<style scoped>

input:invalid {
  box-shadow: 0 0 0 0.25rem rgba(255, 0, 0, 1);
}

.invalid {
  color: rgba(255, 0, 0, 1);
}

.z-toast-container {
  z-index: 1000 !important;
}

</style>
