import {Scope} from "./Scope";
import {Affiliation} from "./Affiliation";
import {PublicAuthorization} from "@/data/account-api-types";

export class AccountInfo {
  initials: string = "";
  mainLocations: string = "";
  affiliations: Affiliation[];
  publicAuthorizations: PublicAuthorization[] = [];

  constructor() {
    this.affiliations = [
      new Affiliation(Scope.KULeuven),
      new Affiliation(Scope.Luca),
      new Affiliation(Scope.Odisee),
      new Affiliation(Scope.ThomasMore),
      new Affiliation(Scope.UCLL),
      new Affiliation(Scope.Vives)
    ]
  }

  reset() {
    this.initials = "";
    this.mainLocations = "";
    this.publicAuthorizations = [];
    for (const affiliation of this.affiliations) {
      affiliation.reset();
    }
  }
}
