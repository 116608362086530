export class IdVerificationInfoStringified {
  userName: string = "";
  firstName: string = "";
  lastName: string = "";
  email: string = "";
  type: string = "";
  orgUnits: string = "";
  programmes: string = "";
  degrees: string = "";
  affiliations: string = "";
  mainLocations: string = "";
  kulCampuses: string = "";
  timestamp: string = "";
  photo: string = "";

  constructor(
    userName: string,
    firstName: string,
    lastName: string,
    email: string,
    type: string,
    orgUnits: string,
    programmes: string,
    degrees: string,
    affiliations: string,
    mainLocations: string,
    kulCampuses: string,
    timestamp: string,
    photo: string
  ) {
    this.userName = userName;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.type = type;
    this.orgUnits = orgUnits;
    this.programmes = programmes;
    this.degrees = degrees;
    this.affiliations = affiliations;
    this.mainLocations = mainLocations;
    this.kulCampuses = kulCampuses;
    this.timestamp = timestamp;
    this.photo = photo;
  }

  reset() {
    this.userName = "";
    this.firstName = "";
    this.lastName = "";
    this.email = "";
    this.type = "";
    this.orgUnits = "";
    this.programmes = "";
    this.degrees = "";
    this.affiliations = "";
    this.mainLocations = "";
    this.kulCampuses = "";
    this.timestamp = "";
    this.photo = "";
  }
}
