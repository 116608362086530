import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2490a6a2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "rainbowcontainer" }
const _hoisted_2 = { class: "rainbow" }
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_qrcode_vue = _resolveComponent("qrcode-vue")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", { href: _ctx.generateQRcode }, [
        _createVNode(_component_qrcode_vue, {
          class: "qrcode",
          size: 100,
          "render-as": 'svg',
          value: _ctx.generateQRcode
        }, null, 8, ["value"])
      ], 8, _hoisted_3)
    ])
  ]))
}