import {createApp} from 'vue';
import {createStore} from "vuex";
import App from './App.vue';
import router from './router';
import {i18n} from "@/i18n/config";
import {BootstrapVue3, BToastPlugin} from 'bootstrap-vue-3';
// import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';
import axios from "axios";
import {UserInfo} from "@/data/UserInfo";
import {createMetaManager} from "vue-meta";
import { UserApiResponse } from './data/account-api-types';
import AccountApiClient from './service/account-api-client'

/*
 * An environment parameter can be of type 'string' or be 'undefined'. However,
 * the Axios client expects an argument of type 'string'. This construction
 * will delegate eventual errors to runtime.
 */
const USER_API_PATH: string = process.env.VUE_APP_API_USER_PATH ?? "";

const store = createStore({
  state() {
    return {
      userInfo: {
        name: "",
        cardNumber: "",
        username: "",
        displayName: "",
        photo: ""
      }
    };
  },
  getters: {
    getUserInfo(state: any) {
      return state.userInfo;
    }
  },
  mutations: {
    resetState(state: any) {
      state.userInfo = {};
    },
    setUserInfo(state: any, userInfo: UserInfo) {
      state.userInfo = userInfo;
    }
  },
  actions: {
    async fetchUserInfo({commit}) {
      try {
        const data = (await axios.get<UserApiResponse>(USER_API_PATH)).data;
        const userInfo = new UserInfo();
        userInfo.name = data.name;
        userInfo.cardNumber = data.KULCardNumber?.at(0) ? data.KULCardNumber[0] : "";
        userInfo.username = data.preferred_username;
        userInfo.displayName = `${userInfo.name} (${userInfo.username})`;

        try {
          const accountApiClient = new AccountApiClient();
          const photo = (await accountApiClient.getMyPhoto()).photo;
          userInfo.photo = photo ? `data:image/jpeg;base64,${photo}` : require('@/assets/images/default-picture.jpg');
        } catch (e) {
          userInfo.photo = require('@/assets/images/default-picture.jpg');
        }

        commit('setUserInfo', userInfo);
      } catch (error: any) {
        commit('resetState')
      }
    }
  }
})


createApp(App)
  .use(router)
  .use(i18n)
  .use(BootstrapVue3)
  .use(BToastPlugin)
  .use(createMetaManager())
  .use(store)
  .mount('#app')
