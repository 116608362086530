<template>
  <div v-show="!state.isLoading">
    <div v-if="userInfoFromStore.cardNumber !== ''">
      <div class="d-flex justify-content-center mt-3">
        <div class="d-flex flex-column justify-content-between card">

          <div class="d-flex align-items-center card-header cardHeaderStyle">
              <img class="cardLogoHeader" src="@/assets/img/hostedby_associatie.svg" alt="Hosted by association KU Leuven"/>
          </div>

          <div class="d-flex flex-column justify-content-center card-body cardBodyStyle mx-3">
            <div class="d-flex flex-row justify-content-between">
              <div class="qrContainer">
                <QrCode :cardNr="userInfoFromStore.cardNumber"></QrCode>
              </div>

              <div class="photoContainer">
                <img :src="userInfoFromStore.photo" alt="User ID">
              </div>
            </div>

            <div class="d-flex flex-column">
              <span class="nameContainer">
                {{userInfoFromStore.name}}
              </span>

              <span class="dateContainer">
                {{t('mycard.validationDate')}} {{currentDate}}
              </span>
            </div>
          </div>

          <div class="d-flex justify-content-around card-footer cardFooterStyle">
            <img class="cardLogoFooter" src="@/assets/img/kuleuven.svg" alt="Logo KU Leuven"/>
            <img class="cardLogoFooter" src="@/assets/img/luca.svg" alt="Logo Luca"/>
            <img class="cardLogoFooter" src="@/assets/img/odisee.svg" alt="Logo Odisee"/>
            <img class="cardLogoFooter" src="@/assets/img/thomasmore.svg" alt="Logo ThomasMore"/>
            <img class="cardLogoFooter" src="@/assets/img/ucll.svg" alt="Logo UCLL"/>
            <img class="cardLogoFooter" src="@/assets/img/vives.svg" alt="Logo Vives"/>
          </div>

        </div>
      </div>
    </div>

    <div v-else>
      <div class="alert alert-warning" role="alert">
        {{ t('error.cardNotFound')}}
      </div>
    </div>

  </div>
</template>

<script>
import {useI18n} from "vue-i18n";
import {mapActions, mapGetters} from "vuex";
import QrCode from "@/components/QrCode.vue";
import {useMeta} from "vue-meta";
import {computed, reactive} from "vue";

export default {
  name: "MyCard",
  components: {
    QrCode
  },
  setup() {
    useMeta({
      title: 'Associatie KU Leuven - kaart'
    })
    const {t, locale} = useI18n();

    const state = reactive({
      isLoading: true
    })

    const currentDate = computed( () => {
      const today = new Date();
      return new Intl.DateTimeFormat(
        locale.value.toLowerCase() === 'nl' ? 'nl-BE' : 'en-US',
        { day: 'numeric',
          month: 'long',
          year: 'numeric'
        }).format(today).replace(',', '');
    })

    return {state, t, currentDate, locale};
  },
  async created() {
    await this.fetchUserInfo()
      .then(() => {
        if (!this.userInfoFromStore.name) {
          window.location.replace(window.location.href);
        } else {
          this.state.isLoading = false;
        }
      })
  },
  computed: {
    ...mapGetters({userInfoFromStore: "getUserInfo"}),
    myPublicUrl() {
      return window.location.protocol + '//' + window.location.host + '/' + this.userInfoFromStore.cardNumber;
    }
  },
  methods: {
    ...mapActions(['fetchUserInfo'])
  }
}
</script>

<style>
.card {
  background-color: #cce1ec;
  color: #333;
  width: 400px;
  /*height: 240px;*/
  height: fit-content;
  border: 1px solid #595959;
  border-radius: 10px;
  padding: 0;
  margin-bottom: 25px;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.3);
}
.cardHeaderStyle {
  background-color: #1b8db0;
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
  border: transparent;
  height: 30px;
  margin-bottom: 15px;
}
.cardBodyStyle {
  padding-bottom: 0.5rem !important;
  /*height: 130px;*/
  background-color: #cce1ec;
}
.cardFooterStyle {
  border: transparent;
  background-color: transparent;

  border-bottom-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}
.cardLogoHeader {
  width: 80px;
  z-index: 2;
  position: relative;
  top: 15px;
  left: 5px;
}
.cardLogoFooter {
  height: 15px;
}
.photoContainer img {
  max-width: 114px;
  max-height: 114px;
  width: 100%;
  height: 100%;
  min-width: 100px;
  min-height: 114px;
  border-radius: 5px;
  object-fit: cover;
}
.nameContainer {
  text-align: left;
  font-weight: bold;
  font-size: 1.3em;

  margin-top: 10px;

  display: -webkit-box;
  /*For more lines increase clamp*/
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;

  hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
}
.dateContainer {
  text-align: left;
  font-weight: 600;
  font-size: 1.1em;
}
</style>
