
import QrcodeVue from "qrcode.vue";
import {defineComponent} from "vue";

export default defineComponent({
  name: "QrCode",
  components: {
    QrcodeVue
  },
  props: {
    cardNr: String
  },
  computed: {
    generateQRcode() {
      return window.location.protocol + '//' + window.location.host + '/' + this.cardNr;
    }
  },
})
