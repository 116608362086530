
import {defineComponent, onMounted, reactive} from "vue"
import {useI18n} from "vue-i18n";

export default defineComponent({
  name: 'ChangeLocale',
  setup() {
    const locale = useI18n().locale;
    const state = reactive({
      otherLocale: ''
    })

    onMounted(() => {
      const initialValue = document.cookie.split(";")
        .filter((item) => item.trim().startsWith('language'))[0]?.split('=')[1];
      locale.value = initialValue ?? 'nl';
      state.otherLocale = locale.value === 'nl' ? 'en' : 'nl';
    })

    function changeLocale() {
      locale.value = state.otherLocale === 'NL' ? 'nl' : 'en';
      state.otherLocale = locale.value.toUpperCase() === 'NL' ? 'EN' : 'NL';
      document.cookie = `language=${locale.value}`;
    }

    return {state, changeLocale}
  }
});
