
import {defineComponent, PropType} from 'vue';
import AffiliationItem from '@/components/AffiliationItem.vue';
import {PublicAuthorization} from "@/data/account-api-types";
import {Locales} from "@/i18n/config"
import {useI18n} from "vue-i18n";
import { Affiliation } from '@/data/Affiliation';

export default defineComponent({
  name: 'AffiliationList',
  components: {
    AffiliationItem
  },
  props: {
    affiliations: {
      type: Object as PropType<Array<Affiliation>>,
      required: true
    },
    mainLocations: {
      type: String,
      required: true
    },
    authorizations: {
      type: Object as PropType<Array<PublicAuthorization>>,
      required: true
    }
  },
  setup() {
    const {t} = useI18n()
    const locale = useI18n().locale;
    return {locale, Locales, t}
  }
});
