import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    class: "btn btn-link",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeLocale && _ctx.changeLocale(...args)))
  }, _toDisplayString(_ctx.state.otherLocale), 1))
}