import {IdVerificationInfo} from "@/data/account-api-types";
import { AppIdError } from "@/data/AppIdError";
import dayjs from "dayjs";

export default class UtilService {
  private dammTable = [
    [0, 3, 1, 7, 5, 9, 8, 6, 4, 2],
    [7, 0, 9, 2, 1, 5, 4, 8, 6, 3],
    [4, 2, 0, 6, 8, 7, 1, 3, 5, 9],
    [1, 7, 5, 0, 9, 8, 3, 4, 2, 6],
    [6, 1, 2, 3, 0, 4, 5, 9, 7, 8],
    [3, 6, 7, 4, 2, 0, 9, 5, 8, 1],
    [5, 8, 6, 9, 7, 2, 0, 1, 3, 4],
    [8, 9, 4, 5, 3, 6, 2, 0, 1, 7],
    [9, 4, 3, 8, 6, 1, 7, 2, 0, 5],
    [2, 5, 8, 1, 4, 3, 6, 7, 9, 0]
  ];

  public validateChecksum(input: string) {
    if (this.validLength(input)) {
      if (this.damm(input) !== '0') {
        throw new Error("error.invalidChecksum");
      } else {
        return true;
      }
    }
  }

  public validLength(input: string) {
    return input.length === 16;
  }

  private damm(input: string) {
    if (!input.match(/^\d+$/)) {
      input = input.replace(/\D/g, '');
    }
    let row = 0;
    for (let i = 0; i < input.length; i++) {
      const col = parseInt(input.charAt(i));
      row = this.dammTable[row][col];
    }
    return row.toString();
  }

  // public storeUserInfo(userInfo: IdVerificationInfoStringified) {
  //   const stringifiedUserInfo = JSON.stringify(userInfo);
  //   let currentList = this.getLocalStorageList("userInfoList");
  //   if (!currentList) {
  //     localStorage.setItem("userInfoList", JSON.stringify([userInfo]));
  //   } else {
  //     let currentListAsJson = JSON.parse(currentList);
  //     const newUserInfoToBeAdded = JSON.parse(stringifiedUserInfo);
  //     currentListAsJson = currentListAsJson.filter((item: any) => item.userName !== newUserInfoToBeAdded.userName);
  //     currentListAsJson.unshift(newUserInfoToBeAdded);
  //     currentList = JSON.stringify(currentListAsJson);
  //     localStorage.setItem("userInfoList", currentList);
  //   }
  //   return currentList;
  // }

  public storeUserInfo(userInfo: IdVerificationInfo) {
    userInfo.timestamp = dayjs().format('DD/MM/YYYY HH:mm:ss.SSS');
    const stringifiedUserInfo = JSON.stringify(userInfo);
    let currentList = this.getLocalStorageList("userInfoList");
    if (!currentList) {
      localStorage.setItem("userInfoList", JSON.stringify([userInfo]));
    } else {
      let currentListAsJson = JSON.parse(currentList);
      const newUserInfoToBeAdded = JSON.parse(stringifiedUserInfo);
      currentListAsJson = currentListAsJson.filter((item: any) => item.userName !== newUserInfoToBeAdded.userName);
      currentListAsJson.unshift(newUserInfoToBeAdded);
      currentList = JSON.stringify(currentListAsJson);
      localStorage.setItem("userInfoList", currentList);
    }
    return currentList;
  }

  public getLocalStorageList(key: string) {
    return localStorage.getItem(key);
  }

  public determineSuccessAndErrorMessageBasedOnCardStatus(cardStatus: string | undefined, state: any) {
    switch (cardStatus) {
      case "REGISTREERBAAR":
        this.setSuccessMsg("cardStatus.activatable", state);
        break;
      case "AANGEVRAAGD":
        this.setErrorMsg("cardStatus.aangevraagd", state);
        break;
      case "EXPORT":
        this.setErrorMsg("cardStatus.export", state);
        break;
      case "ACTIEF":
        this.setSuccessMsg("cardStatus.active", state);
        break;
      case "INGETROKKEN":
        this.setErrorMsg("cardStatus.revoked", state);
        break;
      case undefined:
      default: {
        this.setErrorMsg("cardStatus.unknown", state);
      }
    }
  }

  public setErrorMsg(message: string, state: any) {
    state.errorMsg = message;
    state.successMsg = "";
  }

  public setSuccessMsg(message: string, state: any) {
    state.successMsg = message;
    state.errorMsg = "";
  }

  public resetMsg(state: any) {
    state.successMsg = "";
    state.errorMsg = "";
  }

  public cleanUpCardNr(state: any) {
    state.cardNr = state.cardNr.trim().replaceAll(' ', '').replaceAll('%20', '');
  }

  static cleanAndTrimListOfAppIdAndSns(appIdAndSnList: string) {
    return appIdAndSnList
      .split(/\r?\n/)
      .map(appIdAndSn => this.normalizeAppIdAndSn(appIdAndSn))
      .filter(appIdAndSn => appIdAndSn);
  }

  static normalizeAppIdAndSn(appIdAndSn: string) {
    return appIdAndSn.trim().replaceAll(/\s/g, '');
  }

  static pushToErrorList(errorList: AppIdError[], appIdAndSn: string, message: string) {
    if (!errorList.map(err => err.applicationId).includes(appIdAndSn)) {
      errorList.push(new AppIdError(appIdAndSn, message));
    }
  }

  static generateId(base: string) {
    return base + Math.random().toString(16).slice(2);
  }
}
