
import {defineComponent} from 'vue';
import {useI18n} from "vue-i18n"
import {useMeta} from "vue-meta";

export default defineComponent({
  name: 'PageNotFound',
  setup() {
    useMeta({
      title: 'Associatie KU Leuven - kaart'
    })
    const {t} = useI18n();
    return {t}
  }
});
