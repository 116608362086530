import { Locales } from "@/i18n/config";
import { Scope } from "./Scope";
import { Facility } from "./Facility";

export class Affiliation {
  readonly scope: Scope;
  facilities: Facility[] = [];
  campuses: string[] = [];

  constructor(scope: Scope) {
    this.scope = scope;
  }

  isActive() {
    return (!Array.isArray(this.facilities) || this.facilities.length !== 0); //Can also: !facilities.length
  }

  reset() {
    this.campuses = [];
    for (const facility of this.facilities) {
      facility.reset();
    }
  }
}
