import { Locales } from "@/i18n/config";

export class Facility {
  beschrijving: string = "";
  description: string = "";
  statuut: string = "";

  static readonly statuutMapNL: Map<string, string> = new Map(
    [
      ["employee", "Actief personeelslid"],
      ["student", "Actief student"],
      ["member", "Lid"],
      ["library-walk-in", "Bibliotheekbezoeker"],
      ["other", "Andere"]
    ]
  );
  static readonly statuutMapEN: Map<string, string> = new Map(
    [
      ["employee", "Active employee"],
      ["student", "Active student"],
      ["member", "Member"],
      ["library-walk-in", "Library visitor"],
      ["other", "Other"]
    ]
  );

  setDescriptions(beschrijving: string, description: string) {
    this.beschrijving = beschrijving;
    this.description = description;
  }

  setStatuutFromName(name: string) {
    const atIndex = name.indexOf('@');
    this.statuut =  atIndex !== -1 ? name.substring(0, atIndex) : name;
  }

  getStatuutInLanguage(lang: string) {
    if (!this.statuut) {
      return '';
    }
    let statuutMap;
    switch (lang) {
      case Locales.NL:
        statuutMap = Facility.statuutMapNL;
        break;
      case Locales.EN:
        statuutMap = Facility.statuutMapEN;
        break;
      default:
        statuutMap = Facility.statuutMapEN;
        break;
    }
    return statuutMap.has(this.statuut) ? statuutMap.get(this.statuut) : statuutMap.get("other");
  }

  isActive() {
    return this.beschrijving.length !== 0;
  }

  reset() {
    this.beschrijving = "";
    this.description = "";
    this.statuut = ""; //TODO: also reset?
  }
}
