<template>
  <div class="alert alert-success mt-4" role="alert">
    {{ t(message) }}
  </div>
</template>

<script>
import {useI18n} from "vue-i18n";
export default {
  name: "NotificationSuccess",
  emits: ['messageShown'],
  props: ['message'],
  data() {
    const {t} = useI18n();
    return {t}
  },
  created() {
    setTimeout(() => this.$emit('messageShown'), 3000);
  }
}
</script>

<style scoped>

</style>

