<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content }}</template>
  </metainfo>
  <!-- GLOBAL HEADER -->
  <nav class="global-header noindex hosted-by hidden-print">
    <div class="container">
      <a class="navbar-brand" href="https://associatie.kuleuven.be/"><img class="logo" alt="logo" src="@/assets/img/hostedby_associatie.svg" width="140" height="53"></a>
    </div>
  </nav>
  <!-- END GLOBAL HEADER -->

    <!-- LOCAL HEADER -->
  <div class="container container-relative">
    <nav class="navbar navbar-expand-md">
      <div class="container-fluid">
        <router-link class="pb-3 navbar-brand" to="/"><i class="bi bi-house-door-fill"></i></router-link>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <i class="bi bi-list"></i>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2">
            <li class="nav-item">
              <router-link class="nav-link" to="/kaart">{{ t('nav.mycard')}}</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/registratie">{{ t('nav.registration') }}</router-link>
            </li>
          </ul>
          <ul class="navbar-nav mb-2">
            <li v-if="userInfoFromStore?.name" class="nav-item">
              <a class="nav-link displayName" href="#">{{ userInfoFromStore.displayName }}</a>
            </li>
            <li class="nav-item">
              <changeLocale class="nav-link"/>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
  <!-- END LOCAL HEADER -->

  <div id="content" class="container" role="main">
    <router-view/>
  </div>

  <footer class="container mt-auto py-4">
    <small>{{ t('info.version') }}: {{ version }}</small>
  </footer>
</template>

<script>

import ChangeLocale from '@/components/ChangeLocale.vue';
import {mapActions, mapGetters} from "vuex";
import {useI18n} from "vue-i18n";
// import 'bootstrap'
// import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import {useMeta} from "vue-meta";

export default {
  name: 'App',
  components: {
    ChangeLocale
  },
  setup() {
    useMeta({
      title: 'Associatie KU Leuven - kaart'
    })
    const {t} = useI18n();
    const version = process.env.VUE_APP_VERSION;
    return {t, version};
  },
  async created() {
    await this.fetchUserInfo();
  },
  computed: {
    ...mapGetters({userInfoFromStore: "getUserInfo"})
  },
  methods: {
    ...mapActions(['fetchUserInfo'])
  }
};
</script>

<style>
.global-header {
    background: #1d8db0;
    color: #fff;
    font-size: .875rem;
    border-bottom: 30px solid #ededed;
}
.global-header .container:first-child, .global-header .header2:first-child {
    height: 40px;
}
.global-header .logo {
    top: 13px;
    position: relative;
}
.navbar-brand, .navbar-brand:hover, .navbar-brand:visited, .navbar-brand:active, .nav-link, .nav-link:hover, .nav-link:visited, .nav-link:active {
  color: black;
}


.nav-link {
  text-transform: uppercase;
}
.displayName {
  text-transform: none!important;
}

.b-toaster {
  max-width: 100%;
}
</style>
