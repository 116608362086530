<template>
  <div class="alert alert-danger mt-5" role="alert">
    {{ t('error.accessDenied.msg') }}
    <br>
    <router-link to="/" class="btn btn-link mt-3">{{ t('error.linkToHomePage') }}</router-link>
    <br>
    <div v-if="reason">
      {{ t('error.accessDenied.redirectedFrom') }}{{ originalUri }}
      <br>
      {{ t('error.accessDenied.reason') }}{{ reason }}
    </div>
  </div>

</template>

<script>
import {useI18n} from "vue-i18n";
import {useMeta} from "vue-meta";

export default {
  name: "AccessDenied",
  setup() {
    useMeta({
      title: 'Associatie KU Leuven - kaart'
    })
  },
  mounted() {
    this.originalUri = this.$route.query.uri;
    this.reason = this.$route.query.reason;
  },
  data() {
    const originalUri = "";
    const reason = "";
    const {t} = useI18n();
    return {t, originalUri, reason}
  }
}
</script>

<style scoped>

</style>
