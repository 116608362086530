
import {computed, reactive} from 'vue';
import {useRouter} from 'vue-router';
import {useI18n} from "vue-i18n";
import AffiliationList from '@/components/AffiliationList.vue';
import {AccountInfo} from '@/data/AccountInfo';
import AccountService from '@/service/service';
import UtilService from "@/service/util-service";
import {useMeta} from "vue-meta";

export default {
  name: 'Home',
  components: {
    AffiliationList
  },
  setup() {
    useMeta({
      title: 'Associatie KU Leuven - kaart'
    })
    const {t, locale} = useI18n();
    const router = useRouter();

    const accountInfo = reactive(new AccountInfo());
    const state = reactive({
      cardNr: "",
      showLoading: false,
      showResults: false,
      errorMsg: "",
      successMsg: "",
      showActivateButton: false
    });

    const service = new AccountService();
    const utilService = new UtilService();

    // Use cardNr from route if present
    const cardNrFromRoute = router.currentRoute.value.params.cardNr;
    if (cardNrFromRoute) {
      state.cardNr = cardNrFromRoute.toString();
      submitCardNr();
    }

    async function submitCardNr() {
      utilService.cleanUpCardNr(state);

      if (state.cardNr.trim()) {
        router.push({name: 'HomeWithCardNr', params: {cardNr: state.cardNr}}); // update cardNr in url

        state.showLoading = true;
        state.showResults = false;
        state.errorMsg = "";
        state.successMsg = "";

        //retrieve accountInfo
        try {
          const newAccountInfo = await service.getAccountInfo(state.cardNr);
          mergeAccountInfo(accountInfo, newAccountInfo);
          state.showResults = true;
        } catch (e) {
          if ((e as Error).message === 'error.cardNotFound') {
            await checkSapForCard();
          } else {
            utilService.setErrorMsg((e as Error).message, state);
          }
        } finally {
          state.showLoading = false;
        }
      }
    }

    async function checkSapForCard(): Promise<void> {
      try {
        const status = await service.getCardStatusFromSAP(state.cardNr);
        state.showActivateButton = status === 'REGISTREERBAAR';
        utilService.determineSuccessAndErrorMessageBasedOnCardStatus(status, state);
      } catch (e) {
        state.showActivateButton = false;
        utilService.setErrorMsg((e as Error).message, state);
      }
    }

    function activateCard() {
      state.showActivateButton = false;
      router.push({name: 'CardActivation', params: {cardNr: state.cardNr}});
    }

    function mergeAccountInfo(oldInfo: AccountInfo, newInfo: AccountInfo): void {
      oldInfo.initials = newInfo.initials;
      oldInfo.affiliations = newInfo.affiliations;
      oldInfo.mainLocations = newInfo.mainLocations;
      oldInfo.publicAuthorizations = newInfo.publicAuthorizations;
    }

    function maskInput(event: Event) {
      const inputElement = (event.target as HTMLInputElement);
      const cursorIndex = inputElement.selectionStart;
      inputElement.value = groupByFour(inputElement.value);
      state.cardNr = inputElement.value;
      if (cursorIndex && cursorIndex % 5 === 0) {
        inputElement.setSelectionRange(cursorIndex + 1, cursorIndex + 1);
      } else {
        inputElement.setSelectionRange(cursorIndex, cursorIndex);
      }
    }

    function groupByFour(anyString: string): string {
      // Remove spaces from the input string
      const stringWithoutSpaces = anyString.replace(/\s+/g, '').replace(/%20/g, '');
      // Build the result string with groups of four characters
      let res = '';
      for (let i = 0; i < stringWithoutSpaces.length; i += 4) {
        res += stringWithoutSpaces.slice(i, i + 4) + ' ';
      }
      // Trim any extra space at the end and return the result
      return res.trim();
    }

    const currentDate = computed( () => {
      const today = new Date();
      return new Intl.DateTimeFormat(
        locale.value.toLowerCase() === 'nl' ? 'nl-BE' : 'en-US',
        { day: 'numeric',
          month: 'long',
          year: 'numeric'
        }).format(today).replace(',', '');
    })

    return {
      t,
      state,
      accountInfo,
      submitCardNr,
      activateCard,
      maskInput,
      groupByFour,
      currentDate,
      locale
    }
  }
};
