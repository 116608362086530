
import {computed, defineComponent, toRefs} from "vue"
import {useI18n} from "vue-i18n"
import {Locales} from "@/i18n/config"
import {Affiliation} from "@/data/Affiliation";
import {ScopeInfo} from "@/data/Scope";

export default defineComponent({
  name: 'AffiliationItem',
  props: {
    affiliation: {
      type: Affiliation,
      required: true
    }
  },
  setup(props) {
    const locale = useI18n().locale;

    const affiliation = toRefs(props).affiliation;

    const name = computed(() => ScopeInfo[affiliation.value.scope].name);
    const imgUrl = computed(() => require('@/assets/img/' + ScopeInfo[affiliation.value.scope].img ));

    return {
      name, imgUrl, locale, Locales
    }
  }
});
