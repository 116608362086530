<template>
  <div class="mb-3">
    <table id="verificationtable" class="w-100">
      <thead>
        <tr>
          <th>Username</th>
          <th>Firstname</th>
          <th>Lastname</th>
          <th>Email</th>
          <th>Type</th>
          <th>Org units</th>
          <th>Programmes</th>
          <th>Degrees</th>
          <th>Affiliations</th>
          <th>Main locations</th>
          <th>KUL campuses</th>
          <th>Timestamp</th>
          <th>Action</th>
        </tr>
      </thead>
    </table>
  </div>
</template>

<script>
import $ from "jquery";
import "jquery/dist/jquery.min.js";
import "../assets/css/buttons.dataTables.min.css";
import "../assets/css/jquery.dataTables.min.css";
import jsZip from "jszip";
import dayjs from "dayjs";
import {onBeforeUnmount, onMounted} from "vue";
import {useI18n} from "vue-i18n";

require("datatables.net/js/jquery.dataTables.min.js");
require("datatables.net-dt");
require("datatables.net-buttons-dt");
require("datatables.net-buttons/js/buttons.colVis.js");

require("datatables.net-buttons/js/buttons.html5.js");
require("datatables.net-responsive-dt");
require("datatables.net-scroller-dt");
window.JSZip = jsZip;

export default {
  name: "IdVerificationTable",
  emits: ["personRemoved"],
  props: {
    users: Array,
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const removeEntryByUsername = (username) => {
      localStorage.setItem(
        "userInfoList",
        JSON.stringify(
          JSON.parse(localStorage.getItem("userInfoList")).filter(
            (personScan) => personScan.userName !== username
          )
        )
      );
      emit("personRemoved");
    };

    onMounted(() => {
      const dataTable = $("#verificationtable").DataTable({
        dom: "Bfrtip",
        data: props.users,
        order: [[11, "desc"]],
        stateSave: true,
        destroy: true,
        stateSaveCallback: function (settings, data) {
          localStorage.setItem("tableState", JSON.stringify(data));
        },
        stateLoadCallback: function () {
          try {
            return JSON.parse(localStorage.getItem("tableState"));
          } catch (e) {
            console.log(e);
          }
        },
        columns: [
          {
            data: "userName",
            title: "Username",
          },
          {
            data: "firstName",
            title: "Firstname",
          },
          {
            data: "lastName",
            title: "Lastname",
          },
          {
            data: "email",
            title: "Email",
          },
          {
            data: "type",
            title: "Type",
          },
          {
            data: "orgUnits",
            title: "Org units",
          },
          {
            data: "programmes",
            title: "Programmes",
          },
          {
            data: "degrees",
            title: "Degrees",
          },
          {
            data: "affiliations",
            title: "Affiliations",
          },
          {
            data: "mainLocations",
            title: "Main locations",
          },
          {
            data: "kulCampuses",
            title: "KUL campuses",
          },
          {
            data: "timestamp",
            title: "Timestamp",
          },
          {
            data: null,
            title: "Action",
          },
        ],
        paging: true,
        responsive: true,
        columnDefs: [
          {
            targets: 12,
            defaultContent: "<a type='button'><i class='bi bi-trash'></i></a>",
          },
          {
            targets: 12,
            className: "noVis text-center",
          },
          {
            targets: [3, 4, 5, 6, 7, 8, 9, 10],
            visible: false,
          },
        ],
        buttons: {
          buttons: [
              {
                extend: "excelHtml5",
                className: "btn btn-primary",
                title: dayjs().format("YYYY-MM-DD_HH-mm-ss_") + "registration",
                exportOptions: {
                  columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
                },
              },
              {
                extend: "csvHtml5",
                className: "btn btn-primary",
                title: dayjs().format("YYYY-MM-DD_HH-mm-ss_") + "registration",
                exportOptions: {
                  columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
                },
              },
              {
                text: "JSON",
                className: "btn btn-primary",
                action: function () {
                  $.fn.dataTable.fileSave(
                    new Blob([localStorage.getItem("userInfoList")]),
                    dayjs().format("YYYY-MM-DD_HH-mm-ss_") + "registration.json"
                  );
                },
              },
              {
                extend: "colvis",
                className: "btn btn-block btn-normal",
                columns: ":not(.noVis)",
              },

          ],
          dom: {
            button: {
            className: 'btn'
          }
          }
        }

      });
      $("#verificationtable tbody").on("click", "a", function (event) {
        event.preventDefault();
        const currentRow = dataTable.row($(this).parents("tr"));
        const username = currentRow.data().userName;
        if (confirm(t("modal.confirmDeletion") + username)) {
          currentRow.remove().draw();
          removeEntryByUsername(username);
        }
      });
    });

    onBeforeUnmount(() => {
      $("#verificationtable").DataTable().destroy();
      $("thead", $("#verificationtable")).remove();
    });

    return { removeEntryByUsername };
  },
};
</script>

<style>
.buttons-columnVisibility.active {
  background-color: lightgray;
}
.buttons-columnVisibility {
  display: block;
  width: 100%;
  margin-bottom: 2px;
}
</style>
