import {InstitutionInfo} from "@/data/InstitutionInfo";

export class SapUserInfo {
  availableInstitutions: InstitutionInfo[] = [];
  alreadyActiveInstitutions: InstitutionInfo[] = [];
  username?: string = "";

  constructor() {
  }

  reset() {
    this.availableInstitutions = [];
    this.alreadyActiveInstitutions = [];
    this.username = "";
  }
}
