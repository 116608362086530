
import AccountService from "@/service/service";
import {defineComponent, reactive} from "vue";
import {mapActions, mapGetters, useStore} from "vuex";
import {SapUserInfo} from "@/data/SapUserInfo";
import {useI18n} from "vue-i18n";
import {useRouter} from "vue-router";
import {SapCardInfo} from "@/data/SapCardInfo";
import UtilService from "@/service/util-service";
import { InstitutionInfo } from "@/data/InstitutionInfo";

export default defineComponent({
  name: "Activation",
  setup() {
    const {t} = useI18n();
    const router = useRouter();

    const store = useStore();
    const sapUserInfo = reactive(new SapUserInfo());
    const sapCardInfo = reactive(new SapCardInfo());
    const state = reactive({
      isLoading: true,
      cardNr: "",
      errorList: [],
      successMsg: "",
      errorMsg: "",
      showPossibleInstitutions: false,
      selectedInstitutionCode: "",
      availableForCardInstitutions: new Array<InstitutionInfo>()
    });
    const service = new AccountService();
    const utilService = new UtilService();

    async function registerCard() {
      if (sapUserInfo?.availableInstitutions?.length === 1) {
        state.selectedInstitutionCode = sapUserInfo?.availableInstitutions[0].institutionCode;
      }
      //TODO : remove username for new api
      await service.postCardRegistration(state.selectedInstitutionCode, store?.state?.userInfo?.username, state.cardNr)
        .then(
          () => {
            utilService.setSuccessMsg("notification.registerCardSuccess", state);
            state.showPossibleInstitutions = false;
          },
          (error) => {
            state.errorList = error.message.split(',');
            utilService.setErrorMsg("", state);
            state.showPossibleInstitutions = false;
          }
        );
    }

    return {
      t,
      state,
      sapUserInfo,
      sapCardInfo,
      registerCard,
      service,
      utilService,
      UtilService,
      router
    }
  },
  async created() {
    this.utilService.resetMsg(this.state);
    await this.fetchUserInfo()
      .then(async () => {
        //check if user is not logged in
        if (!this.userInfoFromStore.name) {
          window.location.replace(window.location.href);
        } else {
          this.state.isLoading = false;
          this.state.cardNr = this.router.currentRoute.value.params.cardNr.toString()
          //check if card linked to cardNr is available for registration
          await this.service.getCardStatusFromSAP(this.state.cardNr)
            .then(
              async (status) => {
                //determine success or error msg
                this.sapCardInfo.status = status;
                this.utilService.determineSuccessAndErrorMessageBasedOnCardStatus(status, this.state);
                //call for all institutionInfo for user
                await this.service.getUserInfoFromSAP(this.state.cardNr)
                  .then(
                    (sapUserInfo) => {
                      this.sapUserInfo.availableInstitutions = sapUserInfo.availableInstitutions;
                      this.sapUserInfo.alreadyActiveInstitutions = sapUserInfo.alreadyActiveInstitutions;
                      this.sapUserInfo.username = sapUserInfo.username;
                      const anyInstitutions = sapUserInfo.availableInstitutions.length === 0;
                      this.state.availableForCardInstitutions = sapUserInfo.availableInstitutions.filter((institution) => institution.availableForCard === true);
                      if (anyInstitutions || this.state.availableForCardInstitutions.length === 0) {
                        this.utilService.setErrorMsg("error.noInstitutionFound", this.state);
                      }
                      if (this.state.availableForCardInstitutions.length === 1) {
                        this.state.selectedInstitutionCode = this.state.availableForCardInstitutions[0].institutionCode;
                      }
                      this.state.showPossibleInstitutions = true;
                    },
                    (error) => {
                      this.state.showPossibleInstitutions = false;
                      this.utilService.setErrorMsg(error.message, this.state);
                    }
                  )
              },
              (reason) => {
                this.utilService.setErrorMsg(reason.message, this.state);
              }
            )

        }
      })
  },
  computed: {
    userInfo() {
      const info = this.$store.state.userInfo;
      return {fullName: info.name, userId: info.username};
    },
    ...mapGetters({userInfoFromStore: "getUserInfo"})
  },
  methods: {
    ...mapActions(['fetchUserInfo'])
  }
})
