export class UserInfo {
  name: string = "";
  cardNumber: string = "";
  username: string = "";
  displayName: string = "";
  photo: string = "";

  constructor() {
  }

  reset() {
    this.name = "";
    this.cardNumber = "";
    this.username = "";
    this.displayName = "";
    this.photo = "";
  }
}
