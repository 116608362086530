import { createI18n } from "vue-i18n"

import en from "./en.json";
import nl from "./nl.json";

export enum Locales {
  EN = 'en',
  NL = 'nl',
}

const messages = {
  [Locales.EN]: en,
  [Locales.NL]: nl
}

export const i18n = createI18n({
    legacy: false, // set to false to use the Compostion API mode
    messages,
    locale: Locales.NL,
    fallbackLocale: Locales.EN
})
