export enum Scope {
  KULeuven = "kuleuven.be",
  Luca = "luca-arts.be",
  Odisee = "odisee.be",
  ThomasMore = "thomasmore.be",
  UCLL = "ucll.be",
  Vives = "vives.be"
}

export const ScopeInfo = {
  [Scope.KULeuven]: {name: "KU Leuven", img: "kuleuven.svg"},
  [Scope.Luca]: {name: "Luca", img: "luca.svg"},
  [Scope.Odisee]: {name: "Odisee", img: "odisee.svg"},
  [Scope.ThomasMore]: {name: "Thomas More", img: "thomasmore.svg"},
  [Scope.UCLL]: {name: "UCLL", img: "ucll.svg"},
  [Scope.Vives]: {name: "Vives", img: "vives.svg"}
}
