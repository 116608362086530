export class InstitutionInfo {
  institutionCode: string = "";
  institutionName: string = "";
  institutionStatus: string = "";
  cardNr: string = "";
  availableForCard: boolean = false;

  constructor(institutionCode: string, institutionName: string, availableForCard: boolean, institutionStatus: string, cardNr: string) {
    this.institutionCode = institutionCode;
    this.institutionName = institutionName;
    this.availableForCard = availableForCard;
    this.institutionStatus = institutionStatus;
    this.cardNr = cardNr;
  }

  reset() {
    this.institutionCode = "";
    this.institutionName = "";
    this.institutionStatus = "";
    this.cardNr = "";
    this.availableForCard = false;
  }
}
