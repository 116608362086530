import {AccountInfo} from "@/data/AccountInfo";
import MappingService from "@/service/mapping-service";
import UtilService from "@/service/util-service";
import {AppIdError} from "@/data/AppIdError";
import AccountApiClient from "./account-api-client";
import {SapUserInfo} from "@/data/SapUserInfo";
import {SapError, SapErrorDetail} from "@/data/account-api-types";

export default class AccountService {
  private accountApiClient: AccountApiClient;
  private utilService: UtilService;
  readonly IMPORT_LIMIT = Number(process.env.VUE_APP_IMPORT_LIMIT);

  constructor() {
    this.accountApiClient = new AccountApiClient();
    this.utilService = new UtilService();
  }

  public async getIdVerificationByAppId(appIdAndSn: string) {
    if (appIdAndSn.match(/[0-9A-Fa-f]+;[0-9]+/)?.at(0) !== appIdAndSn) throw new Error('error.cardNotFound');
    const splitAppIdAndSn = appIdAndSn.split(';');
    try {
      const userInfoData = await this.accountApiClient.getIdVerificationInfo(splitAppIdAndSn[1], splitAppIdAndSn[0]);
      this.utilService.storeUserInfo(userInfoData);
      return userInfoData;
    } catch (error) {
      this.handleError(error);
    }
  }

  public async getIdVerificationPhotoByAppId(appIdAndSn: string) {
    if (appIdAndSn.match(/[0-9A-Fa-f]+;[0-9]+/)?.at(0) !== appIdAndSn) throw new Error('error.cardNotFound');
    const splitAppIdAndSn = appIdAndSn.split(';');
    try {
      const userInfoData = await this.accountApiClient.getIdVerificationPhoto(splitAppIdAndSn[1], splitAppIdAndSn[0]);
      //Do not store locally
      return userInfoData;
    } catch (error) {
      this.handlePhotoError(error);
    }
  }

  public async getAccountInfo(cardNr: string): Promise<AccountInfo> {
    this.utilService.validateChecksum(cardNr);
    try {
      const apiResponse = await this.accountApiClient.getPublicAccountInfo(cardNr);
      return MappingService.convertData(apiResponse);
    } catch (error) {
      this.handleError(error);
    }
  }

  public async getCardStatusFromSAP(cardNr: string): Promise<string> {
    try {
      const cardStatus = await this.accountApiClient.getCardInfoFromSAP(cardNr)
      return cardStatus
    } catch (error) {
      this.handleError(error)
    }
  }

  public async getUserInfoFromSAP(cardNumber: string): Promise<SapUserInfo> {
    try {
      const apiResponse = await this.accountApiClient.getUserInfoFromSAP();
      return MappingService.mapSapUserDataToSapUserInfo(apiResponse, cardNumber);
    } catch (error: any) {
      if (error.response?.status === 404) {
        throw new Error('error.noSapAccountFound');
      } else {
        throw new Error('error.general');
      }
    }
  }

  public async postCardRegistration(institution: string, username: string, cardNumber: string) {
    //TODO : remove username for new api
    await this.accountApiClient.postCardRegistration(institution, username, cardNumber)
      .then(
        () => {
          return "notification.registerCardSuccess";
        },
        (error) => {
          const actualSapError: SapError = error.response.data;
          const newErrorList: string[] = [];
          const errorList: SapErrorDetail[] = actualSapError?.error?.innererror?.errordetails;
          const filteredErrorList = errorList.filter((err) => err.code.startsWith('ZC_CARDREGISTRATION'));
          filteredErrorList.forEach((errorItem) => {
            newErrorList.push(errorItem.message);
          });
          if (newErrorList.length === 0) {
            newErrorList.push("error.generalSapPostIssue");
          }
          throw new Error(newErrorList.join(","));
        }
      )
  }

  public async processAppIdList(appIdAndSnList: string) {
    const errorList: AppIdError[] = [];
    const parsedAppIdList = UtilService.cleanAndTrimListOfAppIdAndSns(appIdAndSnList);
    if (parsedAppIdList) {
      await this.doGetIdVerificationCallForEveryItemInList(parsedAppIdList, errorList);
    }
    return errorList;
  }

  private async doGetIdVerificationCallForEveryItemInList(parsedAppIdList: string[], errorList: AppIdError[]) {
    if (parsedAppIdList.length > this.IMPORT_LIMIT) {
      errorList.push(new AppIdError("", "error.limitNumberOfCalls"))
    } else {
      const chunkSize = 5;
      let current = 0;
      for (let i = 0; i < parsedAppIdList.length; i += chunkSize) {
        const calls = parsedAppIdList
          .slice(i, i + chunkSize)
          .map(appIdAndSn => {
            return async () => {
              try {
                await this.getIdVerificationByAppId(appIdAndSn);
              } catch (error: any) {
                errorList.push(new AppIdError(appIdAndSn, error.message));
              }
              sessionStorage.setItem("importProgress", JSON.stringify({ total: parsedAppIdList.length, current: ++current }));
            }
          });

        await Promise.allSettled(calls.map(c => c()));
      }
    }
  }

  private handleError(error: any): never {
    if (error.request !== undefined && error.response === undefined) {
      // Session expired: reload
      window.location.replace(window.location.href);
    } else if (error.response?.status === 404) {
      throw new Error('error.cardNotFound');
    }
    throw new Error('error.general');
  }

  private handlePhotoError(error: any): Map<String, String> {
    if (error.request !== undefined && error.response === undefined) {
      // Session expired: reload
      window.location.replace(window.location.href);
    } else if (error.response?.status === 403) {
      throw new Error('error.photo.notAllowed');
    }
    throw new Error('error.general');
  }
}
