import {IdVerificationInfoStringified} from "@/data/IdVerificationInfoStringified";
import {
  DegreeData,
  IdVerificationInfo,
  OrgUnitData,
  ProgrammeData,
  PublicAccountInfo,
  SapUserData
} from '@/data/account-api-types';
import {AccountInfo} from "@/data/AccountInfo";
import {Facility} from "@/data/Facility";
import {Scope} from "@/data/Scope";
import {SapUserInfo} from "@/data/SapUserInfo";
import {InstitutionInfo} from "@/data/InstitutionInfo";

export default class MappingService {
  static mapIdVerificationJsonListToObject(input: IdVerificationInfo[]) {
    const stringifiedInfo: IdVerificationInfoStringified[] = [];
    input.forEach((item) => {
      stringifiedInfo.push(this.mapIdVerificationJsonToObject(item));
    })
    return stringifiedInfo;
  }

  static mapIdVerificationJsonToObject(input: IdVerificationInfo) {
    return new IdVerificationInfoStringified(
      input?.userName ?? "",
      input?.firstName ?? "",
      input?.lastName ?? "",
      input?.email ?? "",
      input?.type ?? "",
      this.parseOrgUnits(input?.orgUnits),
      this.parseProgrammes(input?.programmes),
      this.parseDegreeData(input?.degrees),
      input?.affiliations?.join(", ") ?? "",
      input?.mainLocations?.join(", ") ?? "",
      input?.kulCampuses?.join(", ") ?? "",
      input?.timestamp ?? "",
      input?.photo ?? ""
    );
  }

  static parseProgrammes(programmes: ProgrammeData[] | undefined): string {
    let output = "";
    if (programmes === undefined) return output;
    if (programmes.length > 0) programmes.forEach((programme) => output = output.concat(this.concatProgrammeInfo(programme)));
    return output.substring(0, output.length - 2);
  }

  static parseOrgUnits(orgUnits: OrgUnitData[] | undefined): string {
    let output = "";
    if (orgUnits === undefined) return output;
    if (orgUnits.length > 0) orgUnits.forEach((orgUnit) => output = output.concat(this.concatOrgUnitInfo(orgUnit)));
    return output.substring(0, output.length - 2);
  }

  static parseDegreeData(degreeData: DegreeData[] | undefined): string {
    let output = "";
    if (degreeData === undefined) return output;
    if (degreeData.length > 0) degreeData.forEach((degreeData) => output = output.concat(this.concatDegreeData(degreeData)));
    return output.substring(0, output.length - 2);
  }

  static concatProgrammeInfo(programme: ProgrammeData): string {
    return programme?.description + " (" + programme?.academicYear + "-" + programme?.id + "), ";
  }

  static concatOrgUnitInfo(orgUnit: OrgUnitData): string {
    return orgUnit?.name + " (" + orgUnit?.id + "), ";
  }

  static concatDegreeData(degreeData: DegreeData): string {
    return degreeData?.description + " (" + degreeData?.id + "), ";
  }

  static convertData(data: PublicAccountInfo): AccountInfo {
    const accountInfo = new AccountInfo();

    accountInfo.initials = data.initials;

    for (const response of data.affiliations) {
      const accInfoAffPtr = accountInfo.affiliations.filter(affiliation => response.name.includes(affiliation.scope))[0];

      const facility = new Facility();
      facility.setDescriptions(response.beschrijving, response.description);
      facility.setStatuutFromName(response.name);

      accInfoAffPtr.facilities.push(facility);
    }
    accountInfo.affiliations.filter(affiliation => affiliation.scope === Scope.KULeuven)[0].campuses = data.kulCampuses!;

    accountInfo.publicAuthorizations = data.publicAuthorizations;

    if (data.mainLocations !== null) {
      accountInfo.mainLocations = data.mainLocations.join(', ');
    }

    return accountInfo;
  }

  static mapSapUserDataToSapUserInfo(sapUserData: Array<SapUserData>, cardNumber: string): SapUserInfo {
    const sapUserInfo = new SapUserInfo();
    //TODO : might need refactoring in the checkCardNumber method
    const numInstitutions = sapUserData?.length;
    if (numInstitutions === 0) {
      throw new Error("error.noActiveSubscription")
    }
    sapUserData?.forEach(
      (result) => {
        //TODO : might need refactoring in the if below depending on MVC changes
        if (result.inst && result.cardnumber && cardNumber && result.status === "") {
          //These cards will be available for Registration due to their status
          const institutionInfo = new InstitutionInfo(
            result.inst,
            this.mapInstCodeToName(result.inst),
            this.checkCardNumber(result.cardnumber, cardNumber, result.inst),
            "",
            result.cardnumber
          );
          sapUserInfo.availableInstitutions.push(institutionInfo);
        } else {
          //TODO : might need refactoring in the if below depending on MVC changes
          if (result.inst && result.cardnumber && (result.status === "ACTIEF" || result.status === "EXPORT")) {
            //These cards will be shown as already active due to their status
            const institutionInfo = new InstitutionInfo(
              result.inst,
              this.mapInstCodeToName(result.inst),
              false,
              result.status,
              result.cardnumber
            );
            sapUserInfo.alreadyActiveInstitutions.push(institutionInfo);
          }
        }
      }
    );

    return sapUserInfo;
  }

  private static mapInstCodeToName(institutionCode: string) {
    switch (institutionCode) {
      case "50000050":
        return "KU Leuven";
      case "51181994":
        return "LUCA School of Arts";
      case "51813768":
        return "Odisee";
      case "51181998":
        return "Thomas More Mechelen-Antwerpen";
      case "51182002":
        return "Thomas More Kempen";
      case "51182004":
        return "UCLimburg";
      case "51181999":
        return "UCLeuven";
      case "51182000":
        return "VIVES-Noord";
      case "51182001":
        return "VIVES-Zuid";
      default:
        return "Unknown";
    }
  }

  private static checkCardNumber(uninitialisedCardNumber: string, cardNumberToCheckAgainst: string, institutionCode: string): boolean {
    //check if the cardNumber is not set - 0000000000000000
    //check if the first number of the card we're trying to register matches the one required by the institution
    //TODO : might need refactoring in the logic below depending on MVC changes, check response value to determine adjustments - cardNr might be optional
    return (uninitialisedCardNumber === "0000000000000000" && (this.verifyCardVsInstitutionNr(cardNumberToCheckAgainst.substring(0, 1), institutionCode)));
  }

  private static verifyCardVsInstitutionNr(cardPrefixToCheckAgainst: string, institutionCode: string) {
    switch (institutionCode) {
      case "50000050":
        return cardPrefixToCheckAgainst === '1';
      case "51181994":
        return cardPrefixToCheckAgainst === '2';
      case "51813768":
        return cardPrefixToCheckAgainst === '3';
      case "51181998":
        return cardPrefixToCheckAgainst === '4';
      case "51182002":
        return cardPrefixToCheckAgainst === '4';
      case "51182004":
        return cardPrefixToCheckAgainst === '5';
      case "51181999":
        return cardPrefixToCheckAgainst === '5';
      case "51182000":
        return cardPrefixToCheckAgainst === '6';
      case "51182001":
        return cardPrefixToCheckAgainst === '6';
      default:
        return false;
    }
  }
}
