import {IdVerificationInfo, PublicAccountInfo, SapCardData, SapUserData} from "@/data/account-api-types";
import axios from "axios";

const BASE_PATH = process.env.VUE_APP_ACCOUNT_API_PATH;
const csrfToken = document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*=\s*([^;]*).*$)|^.*$/, '$1');
const TIMEOUT = 5000;
//axios.defaults.timeout = TIMEOUT; //Set default timeout

export default class AccountApiClient {

  constructor() {
  }

  public async getIdVerificationInfo(appId: string, serialNr: string): Promise<IdVerificationInfo> {
    const url = `${BASE_PATH}/idverification`;
    const body = {'cardAppId': appId, 'serialNr': serialNr};
    const headers = {
          'Accept': 'application/json',
          'X-XSRF-TOKEN': csrfToken
        }
    const response = await axios.post<IdVerificationInfo>(url, body, {headers});
    return response.data;
  }

  public async getIdVerificationPhoto(appId: string, serialNr: string): Promise<IdVerificationInfo> {
    const url = `${BASE_PATH}/idverification/photo`;
    const body = {'cardAppId': appId, 'serialNr': serialNr};
    const headers = {
          'Accept': 'application/json',
          'X-XSRF-TOKEN': csrfToken
        }
    const response = await axios.post<IdVerificationInfo>(url, body, {headers, timeout: TIMEOUT});
    return response.data;
  }

  public async getMyPhoto(): Promise<IdVerificationInfo> {
    const url = `${BASE_PATH}/account/my/photo`;
    const response = await axios.get<IdVerificationInfo>(url, {timeout: TIMEOUT});
    return response.data;
  }

  public async getPublicAccountInfo(cardNumber: string): Promise<PublicAccountInfo> {
    const url = `${BASE_PATH}/public/account?findByCard=${encodeURI(cardNumber)}`;
    const response = await axios.get<PublicAccountInfo>(url);
    return response.data;
  }

  public async getCardInfoFromSAP(cardNumber: string): Promise<string> {
    const url = `${BASE_PATH}/public/card/${cardNumber}/status`;
    const response = await axios.get<string>(url);
    return response.data;
  }

  public async getUserInfoFromSAP(): Promise<Array<SapUserData>> {
    const url = `${BASE_PATH}/account/my/sapcards`;
    const response = await axios.get<Array<SapUserData>>(url);
    return response.data;
  }

  public async postCardRegistration(institution: string, username: string, cardNumber: string) {
    const url = `${BASE_PATH}/account/my/sapcards`;
    //TODO : remove username for new api
    const body = {"inst": institution, "userName": username, "cardnumber": cardNumber};
    //TODO : will XSRF token still be needed after reverting back to MVC?
    const headers = {
      'Accept': 'application/json',
      'X-XSRF-TOKEN': csrfToken
    }
    const response = await axios.post(url, body, {headers});
    return response.data;
  }
}
