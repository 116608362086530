export class SapCardInfo {
  status?: string = "";
  inst?: string = "";

  constructor() {
  }

  reset() {
    this.status = "";
    this.inst = "";
  }
}
