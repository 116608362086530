export class AppIdError {
  applicationId: string = "";
  errorMessage: string = "";

  constructor(appId: string, errorMessage: string) {
    this.applicationId = appId;
    this.errorMessage = errorMessage;
  }

  reset() {
    this.applicationId = "";
    this.errorMessage = "";
  }
}
