import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import Home from '@/views/Home.vue'
import PageNotFound from '@/views/PageNotFound.vue'
import Registration from "@/views/Registration.vue";
import MyCard from '@/views/MyCard.vue';
import AccessDenied from '@/views/AccessDenied.vue';
import Activation from '@/views/Activation.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/:cardNr',
    name: 'HomeWithCardNr',
    component: Home
  },
  {
    path: '/:catchAll(.*)*',
    name: 'PageNotFound',
    component: PageNotFound
  },
  {
    path: '/registratie',
    name: 'Registratie',
    component: Registration
  },
  {
    path: '/kaart',
    name: 'Mijnkaart',
    component: MyCard
  },
  {
    path: '/access-denied',
    name: 'AccessDenied',
    component: AccessDenied
  },
  {
    path: '/activatie/:cardNr',
    name: 'CardActivation',
    component: Activation
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
